import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Button from "../elements/Button";
import "./header.css";

const Header = ({ pageTitle, pageSubtitle, toggleSidebar }) => {
  // const breakpoint = window.matchMedia("(max-width: 991.98px)");

  return (
    <>
      <div className="mobile-header">
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../images/hessam-icon.png"
          width={36}
          height={36}
          quality={95}
          alt="Profile picture"
        />
        <button className="el-button header-button" onClick={toggleSidebar}>
          <span className="el-button--label">Menu</span>
          <FontAwesomeIcon className="el-button--icon" icon={faBars} />
        </button>
      </div>
      <header className="page-header">
        <div className="title-wrap">
          <span className="page-header--subtitle">{pageSubtitle}</span>
          <br></br>
          <h1 className="page-header--title">{pageTitle}</h1>
        </div>
        <div className="actions-wrap">
          <Link to="/contact">
            <Button label={"Hire Me"} />
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
