import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faConciergeBell,
  faAt,
  faSuitcase,
  faBoxes,
} from "@fortawesome/free-solid-svg-icons";
import "./navigationList.css";

const NavigationList = toggleSidebar => {
  return (
    <ul className="sidebar--nav">
      <li className="sidebar--nav-item">
        <Link
          className="sidebar--nav-link"
          onClick={() => toggleSidebar}
          to="/"
        >
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faCompass} />
          </div>
          Dashboard
        </Link>
      </li>
      <li className="sidebar--nav-item">
        <Link
          className="sidebar--nav-link"
          onClick={() => toggleSidebar}
          to="/work"
        >
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faSuitcase} />
          </div>
          Work
        </Link>
      </li>
      <li className="sidebar--nav-item">
        <Link
          className="sidebar--nav-link"
          onClick={() => toggleSidebar}
          to="/builds"
        >
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faBoxes} />
          </div>
          Builds
        </Link>
      </li>
      <li className="sidebar--nav-item">
        <Link
          className="sidebar--nav-link"
          onClick={() => toggleSidebar}
          to="/services"
        >
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faConciergeBell} />
          </div>
          Services
        </Link>
      </li>
      {/* <li className="sidebar--nav-item">
        <Link className="sidebar--nav-link" onClick={()=>toggleSidebar} to="/about">
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faIdCardAlt} />
          </div>
          About
        </Link>
      </li> */}
      <li className="sidebar--nav-item">
        <Link
          className="sidebar--nav-link"
          onClick={() => toggleSidebar}
          to="/contact"
        >
          <div className="sidebar--nav-icon-wrap">
            <FontAwesomeIcon icon={faAt} />
          </div>
          Contact
        </Link>
      </li>
    </ul>
  );
};

export default NavigationList;
