import React, { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
import "./withsidebar.css";

const LayoutWithSidebar = ({ children, pageTitle, pageSubtitle }) => {
  const [sidebarShow, setSidebarShow] = useState(false);

  const toggleSidebar = () => {
    setSidebarShow(!sidebarShow);
    console.log("yoyo");
  };

  return (
    <>
      <Sidebar sidebarShow={sidebarShow} toggleSidebar={toggleSidebar} />
      <section className="main-wrapper">
        <section className="content-container">
          <Header
            pageTitle={pageTitle}
            pageSubtitle={pageSubtitle}
            toggleSidebar={toggleSidebar}
          />
          <main className="container-fluid">{children}</main>
        </section>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
      </section>
      <div
        className={`sidebar-overly ${sidebarShow ? "show" : ""}`}
        onClick={toggleSidebar}
      ></div>
    </>
  );
};

export default LayoutWithSidebar;
