import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import NavigationList from "../navigation/NavigationList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faDribbble,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import "./sidebar.css";

const Sidebar = ({ toggleSidebar, sidebarShow }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = e => {
    setIsActive(!isActive);
  };

  return (
    <div className={`sidebar ${sidebarShow ? "show" : ""}`}>
      <div className="sidebar--header">
        <div className="sidebar--logo">
          <StaticImage
            className="site-logo"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/site-logo.png"
            quality={95}
            alt="Profile picture"
          />
        </div>
      </div>
      <div className="sidebar--nav-body">
        <NavigationList toggleSidebar={toggleSidebar} />
      </div>
      <div className="sidebar--controls">
        <div className="dropdown">
          <button
            className="button dropdown-button"
            type="button"
            onClick={handleClick}
          >
            <StaticImage
              className="bio-avatar"
              layout="fixed"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/profile-pic.jpg"
              width={32}
              height={32}
              quality={95}
              alt="Profile picture"
            />
            <span>h.khoobkar</span>
            <div className="button--icon-end">
              <FontAwesomeIcon icon={faCaretUp} />
            </div>
          </button>
          <ul className={`dropdown-menu ${isActive ? "is-active" : ""}`}>
            <li>
              <div className="dropdown-menu--header">
                <StaticImage
                  className="bio-avatar"
                  layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../../images/profile-pic.jpg"
                  width={48}
                  height={48}
                  quality={95}
                  alt="Profile picture"
                />
                <span>HESSAM KHOOBKAR</span>
              </div>
            </li>
            <li className="dropdown-item">
              <a
                className="dropdown-item--link"
                href="https://www.instagram.com/hessam_khoobkar/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="button--icon" icon={faInstagram} />
                <span className="dropdown-item--title">Instagram</span>
              </a>
            </li>
            <li className="dropdown-item">
              <a
                className="dropdown-item--link"
                href="https://dribbble.com/hessam_khoobkar"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="button--icon" icon={faDribbble} />
                <span className="dropdown-item--title">Dribbble</span>
              </a>
            </li>
            <li className="dropdown-item">
              <a
                className="dropdown-item--link"
                href="https://www.linkedin.com/in/hessam-khoobkar/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="button--icon" icon={faLinkedin} />
                <span className="dropdown-item--title">LinkedIn</span>
              </a>
            </li>
            <li className="dropdown-item">
              <a
                className="dropdown-item--link"
                href="https://github.com/hessamkhoobkar"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="button--icon" icon={faGithub} />
                <span className="dropdown-item--title">Github</span>
              </a>
            </li>
            <li className="dropdown-item dropdown-divider-wrap">
              <div className="dropdown-divider"></div>
            </li>
            <li className="dropdown-item">
              <button
                className="dropdown-button dropdown-button-sm"
                onClick={handleClick}
              >
                <span className="dropdown-item--title">Close back</span>
                <FontAwesomeIcon
                  className="button--icon button--icon-end"
                  icon={faChevronDown}
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
