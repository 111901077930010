import React from "react";
import "./button.css";

const Button = ({ label }) => {
  return (
    <button className="el-button">
      <span className="el-button--label">{label}</span>
    </button>
  );
};

export default Button;
